import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../api/axios';

const initialState = {
  token: '',
  oauth: false,
  oauthLoading: false,
  account: {},
  user: {},
  loading: false,
  error: [],
  validToken: true,
};

export const submitLogin = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
  try {
    const response = await axios.post(`/auth/login`, credentials);
    const data = response.data.data;

    if (response.status === 200) {
      return data;
    }

    return thunkAPI.rejectWithValue(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const submitOAuthLogin = createAsyncThunk('oauth/oauth-login', async (code, thunkAPI) => {
  try {
    const response = await axios.get(`/auth/oauth-login?code=${code}`);
    const data = response.data.data;

    if (response.status === 200) {
      return data;
    }

    return thunkAPI.rejectWithValue(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUser: (state, actions) => {
      state.user = actions.payload;
    },
    logout: (state, actions) => {
      state.user = {};
      state.token = '';
    },
    updateAccountSetting: (state, { payload }) => {
      state.account = payload;
    },
    sessionExpired: (state) => {
      state.token = '';
      state.validToken = false;
    },
    clearError: (state) => {
      state.error = [];
      state.loading = false;
      state.oauthLoading = false;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(submitLogin.fulfilled, (state, { payload }) => {
      console.log('fullfilled');

      state.user = payload.user;
      state.token = payload.token;
      state.oauth = false;
      state.validToken = true;
      state.loading = false;
      state.error = [];
    });
    builder.addCase(submitLogin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitLogin.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload.error.details;
    });

    // submitOAuthLogin
    builder.addCase(submitOAuthLogin.fulfilled, (state, { payload }) => {
      state.account = payload.account;
      state.token = payload.token;
      state.oauth = true;

      state.validToken = true;
      state.oauthLoading = false;
      state.error = [];
    });
    builder.addCase(submitOAuthLogin.pending, (state, action) => {
      state.oauthLoading = true;
    });
    builder.addCase(submitOAuthLogin.rejected, (state, action) => {
      state.oauthLoading = false;
      state.error = action.payload.error.details;
    });
  },
});

export const authApp = (state) => state.auth;

export const { logout, updateUser, sessionExpired, clearError, updateAccountSetting } =
  AuthSlice.actions;

export default AuthSlice.reducer;
